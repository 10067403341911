import MessageMap from '../i18n.d';

const es: MessageMap = {
  breadCrumbs: {
    home: 'Cargar límites',
    inventory: 'Cargar límites',
    summary: 'Gestión de SKUs limitados',
    clientManagement: 'Gestión de excepciones de POCs',
    summaryInventory: 'Descripción general del inventario',
    atpParameters: 'Gestión de parámetros do ATP',
    help: 'Ayuda',
    criticalInventory: 'Gestión de inventario crítico',
    rulesEdit: 'Edición de reglas',
    criticalLevel: 'Control de inventario automatizado',
    rulesManagement: 'Nivel de inventario crítico',
    rulesCreation: 'Creación y manejo de reglas',
    fileMonitoring: 'Monitoreo de archivos',
  },
  sideMenu: {
    home: 'Cargar límites',
    summary: 'Gestión de SKUs limitados',
    clientManagement: 'Gestión de excepciones de POCs',
    limitPerClient: 'Consultar límites',
    summaryInventory: 'Resumen de inventario',
    atpParameters: 'Parámetros do ATP',
    criticalInventory: 'Inventario crítico',
    fileMonitoring: 'Monitoreo de archivos'
  },
  home: {
    title: 'SKUs zerados',
    modelTitle: 'Descarga de modelo',
    modelText: 'En caso de no tener el archivo patrón, haz clic en el botón abajo para descargar.',
    importTitle: 'Subir planilla',
    importText: 'Importe el listado de SKUs que deben ser limitados en BEES',
    modelButtonSKU: 'Archivo CD',
    modelButtonClient: 'Archivo POC',
    DDCOption: 'CD',
    clientOption: 'POC',
    initialDate: 'Fecha  de inicio',
    endDate: 'Fecha de final',
    dialogTitle: '¿Confirma el envio del archivo?',
    clientDialogText: 'Tipo de límite: {value}',
    clientDialogDuration: 'Duración: <b>strong duration</b>',
    clientDialogPeriod: 'Período: <b>strong period</b>',
    clientTabDateWarning: 'Los límites seran procesados fuera del horario comercial',
    managementTittle: 'Administración',
    managementDialog: 'Para ver y administrar SKU limitados, haga clic en el botón a continuación.',
    managementButton: 'Administrar límites',
  },
  summary: {
    headerTitle: 'Gestión limitada de SKU',
    title: 'Lista de SKUs por CD',
    titleFilters: 'Filtrar datos',
    downloadTitle: 'Lista de informes anteriores',
    importLink: 'Subir archivo',
    selectAllDDC: 'Selecione todos los CDs',
    selectAllClients: 'Selecione todos los CDs',
    selectFieldGeo: 'Seleccione una región',
    selectFieldDDC: 'Seleccione un CD',
    ddcCheckboxCount: '{count} de {total} CDs seleccionados',
    ddcCount: '{value} CDs en total',
    ddcSelectedCount: '{value} CDs seleccionados',
    clientCount: '{value} CDs en total',
    clientSelectedCount: '{value} CDs seleccionados',
    skuCount: '{value} SKUs en total',
    skuSelectedCount: '{value} SKUs seleccionados',
    downloadRequestSuccess:
      '¡Tu descarga fue solicitada con éxito! Use la pestaña de descargas para descargar el archivo',
    search: 'Opciones de búsqueda',
    levelRegular: 'Regular',
    levelCritical: 'Crítico',
    filter: {
      placeHolder: 'Buscar por código o descripción del SKU',
      optional: '(Opcional)',
    },
  },
  clientManagement: {
    title: 'Gentión de POCs por excepción',
    subTitle:
      'Importe el listado de POCs que tienen alguna excepción en el registro de CDs. Este listado va a reemplazar el CD importado por BEES',
    importButton: 'Importar listado de POCs',
    modelFileButton: 'Archivo modelo',
    modalTitle:
      'Selecione o arquivo .CSV com a lista de POCs e CDs que serão tratados como exceção',
    importSuccess: '¡Excepción de CMM importados con éxito!',
    importError: '¡Ocurrió un error al importar el archivo CSV!',
  },
  stockBalance: {
    title: 'Resumen de inventario actual',
    subTitle: 'Resumen general',
    filterTitle: 'Informe personalizado',
    filterSubTitle: 'Cree un informe con los datos del inventario del cliente de BEES.',
    skuFilterTooltip: 'Busque SKU escribiendo o pegando en el campo a continuación',
    zeroValueLabel: 'Esconder valores de existencias cero',
    emptyTable: 'El informe personalizado de inventario aparecerá aquí.',
    distributionCenterTags: 'Centros de distribución seleccionados:',
    bannerAlertText: 'Exportación de archivos. Consulte la página Monitoreo de archivos para obtener actualizaciones de estado y descargue los archivos cuando estén listos.',
    bannerAlertButton: 'Ir a monitoreo de archivos',
    subTitleExpandTable: 'Detalles de ATP',
    infiniteText: `No hay información para la fecha seleccionada ya que el SKU tiene stock infinito.`,
    unvailableText: `No hay información para la fecha seleccionada ya que el SKU no tiene stock disponible.`,
    hasAllUnavailableOrInfinite: `No hay información para la fecha seleccionada ya que el SKU no tiene stock disponible / infinito.`,
    table: {
      sku: 'SKU',
      stock: 'Inventario CD',
      reserved: 'Reservado',
      beesStock: 'Inventario BEES',
      outOfStock: 'Agotado en',
      restocked: 'Reabastecido en',
      time: 'Agotado en (Tiempo Total)',
      limit: 'Límite CD',
      beesStockRegularStatus: 'Estado regular',
      beesStockCriticalStatus: 'Estado critico',
      lastUpdated: 'Ultima actualización en',
      pending: 'Pendiente',
      consumed: 'Consumado'
    },
    importModal: {
      title: 'Exportar archivos',
      text: 'Elija los archivos que desea exportar y verifique el progreso en la página de monitoreo de archivos.',
      inventoryTitle: 'Informe personalizado de inventario',
      inventoryText: 'Exporte la región seleccionada, el centro de distribución, el código SKU y la información de inventario.',
      orderTitle: 'Lista de pedidos reservados',
      orderText: 'Exporta una lista con todos los pedidos pendientes y confirmados.',
      disableOrderImport: 'No hay pedidos para exportar. Sólo se exportan los pedidos con reserva pendiente.'
    },
    stockPerDayChart: {
      subTitleDCStock: 'Acciones futuras de CD',
      reserved: 'Reservado',
      stock: 'Inventario BEES',
      infinite: 'Stock infinito',
      unavailable: 'Indisponible',
      tooltip: {
        dcStock: 'Inventario CD',
        infiniteText: 'Infinito',
      }
    }
  },
  ATPParameters: {
    title: 'Parámetros do ATP',
    buttons: {
      add: 'Añadir un nuevo parámetro',
    },
    table: {
      ddc: 'CD',
      skuCount: 'Total SKU',
      type: 'Tipo de parámetro',
      created: 'Fecha de creación',
      edited: 'Última modificación',
    },
    modal: {
      addParameter: 'Añadir parámetro',
      editParameter: 'Editar parámetro',
      deleteParameter: 'Remover parámetro',
      addTitle: 'Añadir un nuevo parámetro',
      editTitle: 'Editar parámetro',
      allDDC: 'Todos DDCs',
      especificDDC: 'DDCs especificos',
      allSKU: 'Todos SKUs',
      especificSKU: 'SKUs especificos',
      typeAlert:
        'Al cambiar los tipos de parámetros, elimine cualquier DDC y SKU agregado de los campos a continuación',
      successCreation: 'Se ha añadido correctamente un nuevo parámetro.',
      successEdition: 'Un nuevo parámetro ha sido editado con éxito.',
      successDelete: 'Se ha eliminado un parámetro.',
      labels: {
        paramterType: 'Tipo de parámetro',
        ddc: `Selecione DCs`,
        sku: `Selecione SKUs`,
        autocompleteDDC:
          'Use el campo a continuación para escribir el código de DC y presione ENTER para agregar.',
        autocompleteSKU:
          'Use el campo a continuación para escribir el código de SKU y presione ENTER para agregar.',
        placeholderDDC: 'Ingrese el código DDC',
        placeholderSKU: 'Ingrese el código SKU',
        reservationWindow: 'Ventana de reserva',
        reservationWindowTooltip:
          'Cuántos días antes de la fecha de entrega debemos tener en cuenta para reservar los pedidos',
        reservationWindowText: 'Se requiere al menos una ventana de entrega para todo el DDC.',
        statusReservation: 'Estado para detener la reserva',
        statusReservationTooltip:
          'Qué estado del pedido de BEES debe considerar el ATP para detener la reserva para cada pedido',
      },
      reservationWindowOptions: {
        single: '1 día',
        plural: '{value} días',
        dayLabel: 'para todo el DC',
        addNew: 'Agregar otra ventana',
      },
      errors: {
        ddc: 'Se deben ingresar los DC específicos',
        sku: 'Se deben ingresar los SKU específicos',
        reservationStatus: 'Se debe seleccionar al menos un estado de reserva',
        blockedDDC: 'Ya existe ese DC en otro parámetro - {value}',
      },
      deleteParameterDialog: {
        title: 'Eliminar este parámetro?',
        text: 'Esta acción es permanente y no se puede deshacer.',
      },
    },
    statusConsume: {
      PLACED: 'Order placed',
      CONFIRMED: 'Order confirmed',
      MODIFIED: 'Order modified',
      INVOICED: 'Order invoiced',
      IN_TRANSIT: 'Order in-transit',
      DELIVERED: 'Order delivered',
      PARTIAL_DELIVERY: 'Order partial delivered',
    },
    status: {
      activation: 'Activación',
      exception: 'Excepción',
    },
    errors: {
      sameSKU: 'No puede insertar el mismo sku para la opción de días diferentes',
    },
  },
  selector: {
    empty: 'seleccionados um CD',
  },
  buttons: {
    import: 'Importar estoque',
    blockSKU: 'Bloquear SKU',
    unblockSKU: 'Desbloquear SKU',
    unblock: 'Desbloquear',
    confirm: 'Confirmar',
    cancel: 'Cancelar',
    importText: 'Haga clic para buscar en su computadora',
    continue: 'Continuar',
    search: 'Búsqueda',
    back: 'Voltar',
    review: 'Revisar',
    exit: 'Sair',
    create: 'Crear',
    edit: 'Editar',
    deactivate: 'Desactivar',
    download: 'Download',
    apply: 'Aplicar',
    applyChanges: 'Aplicar alterações',
    deleteYes: 'Si, borrar',
    goBack: 'Regresa',
    showInventory: 'Mostrar inventario',
    refresh: 'Atualizar',
    gotIt: 'Entendi',
    export: 'Exportar'
  },
  importModal: {
    cancelButton: 'Cancelar',
    submitButton: 'Importar',
    title:
      'Selecione o arquivo .CSV com a listagem de estoque, ao clicar em importar os SKUs do arquivo serão bloqueados para venda no BEES',
    fileInput: 'Clique aqui para selecionar o arquivo',
    formatErrorMessage: 'Tipo de archivo no válido, seleccione un tipo de archivo {value}',
    requestSuccess: '¡Límites importados con éxito!',
    requestFailed: '¡Ocurrió un error al importar el archivo CSV!',
    requestFailedEmptyList: 'El archivo no tiene SKU agotados para bloquear',
  },
  SKUUnblockModal: {
    title: '¿Confirma el desbloqueo de los siguientes SKUs para ventas en BEES?',
    confirm: 'Sí',
    cancel: 'No',
    success: '¡Desbloqueado con éxito!',
    failure: '¡Hubo un error al desbloquear los SKU!',
  },
  SKUblockSummaryModal: {
    title: '¿Confirma el envio del archivo?',
    subTitle: 'Consulte a continuación los DC afectados y la cantidad de SKU:',
    type: 'Tipo de límite:',
    cd: 'DC',
    countCd: '{cd} DC',
    countSKU: '{sku} SKUs',
    tableDC: 'Centro de Distribución',
    tableSKU: 'Número de SKU',
    failure: '¡Hubo un error al bloquear la lista de SKU!',
    startDate: 'Inicio',
    endDate: 'Fin',
    validity: 'Validez:',
  },
  BoxImportData: {
    title: 'Última actualización BEES',
  },
  requestDeliveryCenter: {
    errorList: 'Hubo un error al enumerar los CD',
  },
  Pagination: {
    selectedOne: '{value} elemento seleccionado',
    selectedMany: '{value} elementos seleccionados',
    of: 'en',
  },
  DataGridBlockedSKU: {
    celSku: 'Código y nombre',
    celLimit: 'Límite',
    celAvailable: 'Disponible',
    celDateRange: 'Rango de fechas',
    celLimitSource: 'Límite fuente',
    celStartDate: 'Validez inicial',
    celEndDate: 'Validez final',
    celAction: 'Acción',
    celPOC: 'PDV',
    celDuration: 'Duración',
    celStatus: 'Status',
    emptyRows: 'Seleccione los CDs en el campo de arriba.',
    emptyRowsClients: 'Seleccione los POCs en el campo de arriba.',
    noRows: 'No se encontró SKU',
    errorAccount: 'Cuenta no encontrada',

    errorRequest: 'Hubo un error al extraer la lista de SKU bloqueada',
    searchField: 'Busque por código o descripción del SKU',
    buttonUnblock: 'Desbloquear',
    noPermission: 'Sem permissão',
    status: {
      success: 'Enviado',
      pending: 'Pendiente',
      live: 'Ativo',
    },
  },
  Loading: {
    defaultLoadingBox: 'Los datos están siendo procesados, por favor espere',
  },
  datePicker: {
    format: 'dd/MM/yyyy',
    formatTime: 'dd/MM/yyyy HH:mm',
    confirm: 'OK',
    cancel: 'Cancelar',
    incorrectDate: 'Formato de fecha incorrecto',
    minDate: 'No puede ser inferior a la fecha actual',
    minDateEnd: 'No puede ser anterior a la fecha de inicio',
    defaultDateTimeLabelInitial: 'Fecha y hora de inicio',
    defaultDateTimeLabelEnd: 'Fecha y hora final',
  },
  importClientLimit: {
    success: '¡Límites por POC importados con éxito!',
    failure: '¡Ocurrió un error al importar el archivo!',
  },
  uploadValidation: {
    fileSize: 'Excede el límite de tamaño de archivo. El límite de tamaño de archivo es {value}MB',
  },
  forms: {
    selectAll: 'Seleccionar todo',
    unselectAll: 'Deseleccionar todo',
    searchField: 'Busque por código del SKU',
    emptyAtLeastOne: 'Must have at least one item',
    errorChips: 'Remove the following error chips to continue.',
    errorSameChip: 'No puede agregar un elemento con el mismo nombre',
    searchFieldSKU: 'Código del SKU',
    searchFieldSKUPlaceholder: 'Busque por SKU',
  },
  general: {
    for: 'para',
    or: 'o',
    DDC: 'CD',
    pluralDDC: 'CDs',
    client: 'POC',
    downloads: 'Informes anteriores',
    filters: 'Filtros',
    region: 'Región',
    undo: 'Deshacer',
    optional: 'Opcional',
    show: 'Mostrar opciones',
    hide: 'Ocultar opciones',
    diselectAll: 'Deseleccionar todo',
    applied: 'aplicado',
    autocompletecountLabel: '{value} item seleccionado',
    clearAll: 'Limpiar todo',
    date: 'Fecha',
    yes: 'Si',
    no: 'No',
    today: 'Hoy',
    period: 'Período',
    especificDate: 'Fecha específica',
    startDate: 'Fecha de inicio',
    endDate: 'Fecha de finalización',
    exportBy: 'Exportar por'
  },
  clientTab: {
    title: 'Listado de SKUs por POCs',
    emptyState: 'Utilice los campos anteriores para buscar POCs y SKU',
  },
  ddc: {
    title: 'Listado de SKUs por CD',
    emptyState: 'Utilice los campos anteriores para buscar DCs y SKU',
  },
  uploadList: {
    title: 'Carga',
  },
  uploadHistory: {
    title: 'Histórico de listas',
  },
  limitPerClient: {
    filter: {
      title: 'Consultar límites por POC',
      input: 'Busca por ID del PDV',
      placeHolder: 'Proporciona el ID de la cuenta del PDV',
      toolTip:
        'Los datos se obtienen directamente de BEES para su consulta. Los retrasos en la integración de límites pueden ocasionar discrepancias entre la información mostrada aquí y en la pantalla de gestión de límites.',
    },
    table: {
      empty: 'Busca por ID del PDV',
      sku: 'SKU',
      limit: 'Límite',
      balance: 'Saldo',
      validStart: 'Inicio de validez',
      validEnd: 'Finalización de validez',
      loading: 'Buscando elementos',
      pageSizeOptions: 'Elementos por página: {value}',
      pageNumberIndicator: 'de',
      searchPlaceHolder: 'Buscar por SKU',
      status: 'Status',
      limitType: 'Tipo'
    },
    notfound: '¡No se encontraron resultados!',
    error: {
      size: 'tamaño inválido',
      cpforcpnj: 'CPF/CNPJ inválido',
      length: 'El campo debe tener más de 1 dígito',
    },
    imageText: 'Consulta los límites de SKU activos por PDV',
  },
  exportSKUList: {
    table: {
      requestDate: 'Fecha de solicitud',
      limitType: 'Tipo de límite',
      User: 'Usuario',
      Status: 'Status',
      Actions: 'Acción',
      Info: 'Información',
    },
    filterTag: {
      limitType: '[Tipo de límite] {value}',
      user: '[Usuario] {value}',
      status: '[Estado] {value}',
      dc: '[CD] {value}',
      SKU: '[SKU] {value}',
      name: '[Nombre] {value}',
    },
    status: {
      finished: 'Listo para descargar',
      inProgress: 'En progreso',
      failed: 'Fallado',
    },
  },
  customRequestErrors: {
    importSKUFile: 'No tienes permiso para establecer límites para CMM {value}',
    defaultRequestErrors: 'Ha ocurrido un error inesperado',
    defaultRequestErrorsATP: '¡Ups!, Algo salió mal, por favor inténtelo de nuevo más tarde',
  },
  localErrors: {
    timezoneImport:
      'Error al importar el archivo. Debe seleccionar una zona horaria en su perfil de usuario',
    timezoneDefault: 'Debe seleccionar una zona horaria en su perfil de usuario',
    emptyCountryList:
      'No tiene ningún proveedor asociado con su usuario, comuníquese con el equipo de soporte',
  },
  ModalSelectCountry: {
    title: '¡Bienvenido!',
    text: 'Antes de usar LOAT, seleccione el país que verá.',
    label: 'País:',
    emptyTitle: 'Registro incompleto',
    emptyText:
      'Parece que su registro de usuario no está completo. Póngase en contacto con la persona responsable de su usuario y vuelva a intentarlo más tarde.',
  },
  Countries: {
    AR: 'Argentina',
    AQ: 'Antártida',
    BE: 'Bélgica',
    BR: 'Brazil',
    BO: 'Bolivia',
    CL: 'Chile',
    CO: 'Colombia',
    DE: 'Alemanha',
    DO: 'Dominican Republic',
    EC: 'Ecuador',
    ES: 'España',
    GB: 'Reino Unido',
    HN: 'Honduras',
    ID: 'Indonesia',
    MX: 'Mexico',
    NL: 'Países Bajos',
    PA: 'Panama',
    PE: 'Peru',
    PH: 'Filipinas',
    PY: 'Paraguay',
    SV: 'El Salvador',
    UY: 'Uruguay',
    ZA: 'South Africa',
  },
  ToolTipForTimeZone: {
    title: 'No se ha seleccionado ninguna zona horaria',
  },
  ImportFileDataTable: {
    fileName: 'Nombre del archivo',
    uploadDate: 'Fecha de carga',
    uploadBy: 'Cargado por',
    uploadStatus: 'Estado de carga',
    limitType: 'Tipo de límite',
    lines: 'Lineas',
    successes: 'Éxitos',
    fails: 'Fallas',
    toolTip: {
      titleWithErrors: 'Haga clic para ver todos los errores',
      titleWithoutErrors: 'No hay errores para descarga',
    },
  },
  FiltersTitle: {
    region: 'Región y CD',
    limitType: 'Tipo de límite',
    user: 'Usuario',
    status: 'Estado',
    SKU: 'SKU',
    RuleName: 'Nombre de la regla',
  },
  Placeholders: {
    user: 'Introduzca un e-mail',
    limitType: 'Seleccione un tipo de límite',
    status: 'Seleccione un status',
    searchSKU: 'Buscar SKU',
    RuleName: 'Nombre de la regla de búsqueda',
  },
  ExportLimitTypes: {
    finished: 'Listo para descargar',
    in_progress: 'En progreso',
    failed: 'Fallado',
  },
  DownloadErrorFiles: {
    title: 'Guardar como archivo CSV',
  },
  DialogWithTableError: {
    title: 'Lista de fallas',
    overRows: 'Descarga el archivo para ver todos los errores',
    loading: 'Espere mientras se cargan los datos',
    noRows: 'no se encontró ningún archivo',
    column: {
      line: 'Línea',
      message: 'Descripción de falla',
    },
  },
  UploadStatus: {
    inProgress: 'En progresso',
    uploadSuccessfully: 'Enviado',
    error: 'Falla',
    uploadWithErrors: 'Enviado con errores',
  },
  criticalInventory: {
    title: 'Gestión de inventario crítico',
    subTitle: 'Establezca un nivel de stock crítico y establezca reglas de umbral',
    text: 'Siga los pasos a continuación:',
    stepOneTitle: 'PASO 1. Nivel crítico de existencias',
    stepOneText: 'Establecer valores para el nivel de inventario crítico por CDD y SKU',
    stepTwoTitle: 'PASO 2. Reglas de umbral',
    stepTwoText: 'Cree y administre reglas de límite por CDD y POS',
    form: {
      stepOneText: 'Crear',
      stepOneEditText: 'Para editar',
      stepTwoText: 'Revisar',
      dialogBackTitle: 'Você deseja sair?',
      dialogBackText:
        'El contenido de su regla no se guardará, tendrá que comenzar de nuevo si desea crear la regla.',
      successCreation: '¡Tu regla está creada!',
      successEdition: '¡Tu regla ha sido editada!',
      pocRequestError: 'Todos los POC ingresados ​​no son válidos. Informar los POC existentes',
      StepOne: {
        title: 'Asigne un nombre a su regla y defina el tipo de regla:',
        RuleNameLabel: 'Nombre de la regla',
        RuleNamePlaceholder: 'El nombre debe tener un máximo de 50 caracteres.',
        RuleTypeLabel: 'Tipo de regla',
        RuleDescription: '¿De qué se trata esta regla?',
        RuleDescriptionDDC:
          'Este tipo de regla habilitará o deshabilitará automáticamente un SKU para todos los POS en el DC.',
        RuleDescriptionPOC:
          'Este tipo de regla limitará el POS que elija para tener una cantidad específica de un SKU disponible para la compra.',
        turnOn: 'Activar regla',
        turnOff: 'Deshabilitar regla',
        dialogStatusTitle: '¿Quieres deshabilitar esta regla?',
        dialogStatusText:
          'Esta acción será inmediata y la regla permanecerá inactiva hasta que se vuelva a activar.',
      },
      StepTwo: {
        title: 'Defina un DC para colocar su regla:',
        RegionLabel: 'Región',
        RegionPlaceholder: 'Seleccione una región',
        DDCLabel: 'Centros de distribución',
        DDCPlaceholder: 'Seleccione un CD',
      },
      StepThree: {
        title: 'Elija el producto que será limitado',
        SKULabel: 'SKU',
        SKUPlaceholder: 'Lista de SKUs',
        Qty: 'Cantidad límite',
        actions: 'Comportamiento',
        skuHaveRuleError: 'El sku {value} ya tiene una regla creada.',
      },
      StepFour: {
        title: 'Elige el POC',
        POCLabel: 'POCs',
        POCPlaceholder: 'Lista POC',
        maxPOCWarningTitle: '¡Enviaste más POCs de vista de los permitidos!',
        maxPOCWarningText:
          'La creación de una regla POCS solo le permite cargar hasta 400 POCS, puede optar por eliminar el último número excedente de POCS o regresar y copiar y pegar el valor exacto.',
        maxPOCWarningAccept: 'Eliminar POCs sobrante',
        maxPOCToastError: `No puede agregar un nuevo POC, se alcanzó el límite de {value}`,
        informationTextChip: 'También puede copiar y pegar 400 POC desde su CSV',
      },
      Review: {
        title: 'Revisa tu información',
      },
    },
    Filter: {
      clearStates: 'Limpiar todo',
    },
  },
  rulesManagementList: {
    title: 'Gestión de reglas',
    createRule: 'Crear regla',
    table: {
      emptyTitle: 'Crear regla',
      emptyText: '¡No hay reglas creadas, agregue una y vea la información aquí!',
      name: 'Nombre de la regla',
      type: 'Tipo de regla',
      ddc: 'DC',
      sku: 'SKU',
      qty: 'Cantidad límite',
      poc: `POCs`,
      actions: 'Comportamiento',
      lastEvent: 'Último evento',
      status: 'Status',
    },
  },
  criticalLevel: {
    title: 'Control de inventario automatizado',
    searchPlaceholder: 'Buscar por DDC ou SKU',
    createCriticalLevel: 'Añadir nuevo stock crítico',
    table: {
      emptyTitle: 'Crear una regla de stock crítico',
      emptyText: 'There are no created rules, add one and visualize the information here!',
      ddc: 'DDC',
      sku: 'SKU id',
      criticalLevel: 'Stock crítico',
      created: 'Fecha de creacion',
      updated: 'Última actualización',
    },
    modal: {
      title: 'Añadir nuevo stock crítico',
      titleEdit: 'Editar nivel crítico',
      regionPlaceholder: 'Seleccionar una región',
      skuPlaceholder: 'Seleccionar un DDC',
      skuText: 'Busca un SKU escribiendo o pegando en el campo de abajo',
      criticalLevelInput: 'Agregar valor de nivel crítico',
      criticalLevelPlaceholder: 'Número de entrada',
      successInsert: 'Se ha agregado con éxito un nuevo nivel crítico.',
      successUpdate: 'Cambios aplicados al nivel crítico.',
      successDelete: 'Nivel crítico eliminado.',
      deleteDialogTitle: 'Remover nivel crítico',
      deleteDialogText: 'Esta acción es permanente y no se puede deshacer..',
    },
  },
  fileMonitoring: {
    ATPExport: {
      table: {
        type: 'Tipo',
        fileName: 'Nombre del archivo',
        exportedOn: 'Exportado en',
        exportedBy: 'Exportado por',
        status: 'Status',
        action: 'Acción'
      },
      typeText: {
        inventory: 'Informe personalizado de inventario',
        order: 'Lista de pedidos reservados ATP'
      },
      dialogError: {
        title: 'No se puede procesar el archivo',
        text: 'Estamos enfrentando problemas para procesar el archivo. Vuelve a intentarlo más tarde.'
      }
    }
  },
  notFoundPage: {
    titleAccessDenied: 'Hmm... ¡Acceso denegado!',
    textAccessDenied: `Parece que no tienes permiso para ver esta página.`
  },
  defaultStatusTypes: {
    active: 'Activo',
    inactive: 'Inactivo',
    applied: 'Aplicado',
    processing: 'Procesando',
    error: 'Erro',
    readyDownload: 'Lista para descargar'
  },
};

export default es;
