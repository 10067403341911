import { useAnalyticsService, useAuthenticationService } from 'admin-portal-shared-services';
import { LOCAL_STORAGE_USER_EMAIL } from 'config/constants';
import { useEffect } from 'react';

export const useSegmentAnalytics = (segmentKey: string): void => {
  const analyticsService = useAnalyticsService();
  const jwtDecoded = useAuthenticationService().parseJwt();

  localStorage.setItem(LOCAL_STORAGE_USER_EMAIL, jwtDecoded?.email || '');

  useEffect(() => {
    analyticsService.load(segmentKey);
    analyticsService.identify({ first_name: jwtDecoded?.name, email: jwtDecoded?.email });
  }, [analyticsService, segmentKey]);
};

export default useSegmentAnalytics;
