import { createStore } from 'effector';
import _ from 'lodash';
import { setForceDownloadTab, setInventoryDisableDownloadButton, setOpenCountryModal, setSelectedCountry, setSelectedVendor } from './UserEvents';
import UserState from './UserState';

const initialState: UserState = {
  openCountryModal: false,
  selectedCountry: '',
  selectedVendor: '',
  forceDownloadTab: false,
  inventoryDisableDownloadButton: false
};

const CreateUserStore = createStore(initialState)
  .on(setOpenCountryModal, (state, openCountryModal) =>
    _.cloneDeep({
      ...state,
      openCountryModal,
    })
  )
  .on(setSelectedCountry, (state, selectedCountry) =>
    _.cloneDeep({
      ...state,
      selectedCountry,
    })
  )
  .on(setSelectedVendor, (state, selectedVendor) =>
    _.cloneDeep({
      ...state,
      selectedVendor,
    })
  )
  .on(setForceDownloadTab, (state, forceDownloadTab) =>
    _.cloneDeep({
      ...state,
      forceDownloadTab
    })
  ).on(setInventoryDisableDownloadButton, (state, disableDownloadButton) =>
    _.cloneDeep({
      ...state,
      inventoryDisableDownloadButton: disableDownloadButton
    })
  );


export default CreateUserStore;
