import { HOME_ROUTE, NOT_FOUND_ROUTE } from 'config/routes';
import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { GetFirstPermitedRoute } from 'utils/GetFirstPermitedRoute/GetFirstPermitedRoute';
import { CheckOnePermission } from 'utils/userHasPermission/userHasPermission';
import { useRouteScopes } from '../../hooks/useRouteScopes/useRouteScopes';

interface Props {
  children: React.ReactNode;
}

function PrivateRoute({ children }: Props): JSX.Element {

  let PATHNAME = NOT_FOUND_ROUTE;

  const scopes = useRouteScopes();
  const hasPermission = CheckOnePermission(scopes);
  const location = useLocation();

  if (location.pathname === HOME_ROUTE && !hasPermission) {
    PATHNAME = GetFirstPermitedRoute();
  }

  return <>{!hasPermission ? <Redirect to={{ pathname: PATHNAME }} /> : children}</>;
}

export default PrivateRoute;
