import { Sliders, SortDefault, Warehouse } from '@hexa-ui/icons';
import SearchIcon from '@material-ui/icons/Search';
import ClientExceptionIcon from 'assets/sideBarIcons/clients-exception-management-icon.png';
import StockIcon from 'assets/sideBarIcons/clipboard.svg';
import SKULimitIcon from 'assets/sideBarIcons/sku-limits-icon.png';
import LimitsIcon from 'assets/sideBarIcons/upload-limits-icon.png';
import HelpButton from 'components/HelpButtonIcon/HelpButtonIcon';
import React from 'react';

const AvailabilityIcon = (): JSX.Element => {

    return <img
        src={LimitsIcon}
        alt='Limits icon'
        style={{ maxWidth: '24px' }}
    />;
};

const SummaryIcon = (): JSX.Element => {

    return <img
        src={SKULimitIcon}
        alt='Summary icon'
        style={{ maxWidth: '24px' }}
    />;
};

const LimitsPerClientIcon = (): JSX.Element => {

    return <SearchIcon titleAccess='LimitsPerClientIcon' />;
};

const ClientManagementIcon = (): JSX.Element => {

    return <img
        src={ClientExceptionIcon}
        alt='Client exception icon'
        style={{ maxWidth: '24px' }}
    />;
};

const StockBalanceIcon = (): JSX.Element => {

    return <img
        src={StockIcon}
        alt='Stock balance icon'
        style={{ width: '24px', height: '24px' }}
    />;
};

const CriticalStockIcon = (): JSX.Element => {
    return <Warehouse />;
};

const AtpParametersIcon = (): JSX.Element => {
    return <Sliders />;
};

const FileMonitoringIcon = (): JSX.Element => {
    return <SortDefault />;
};

const HelpButtonIcon = (): JSX.Element => {
    return <HelpButton />;
};

export {
    AtpParametersIcon, AvailabilityIcon,
    ClientManagementIcon, CriticalStockIcon, FileMonitoringIcon, HelpButtonIcon,
    LimitsPerClientIcon,
    StockBalanceIcon,
    SummaryIcon
};

