import { createIntl, createIntlCache, defineMessages, MessageDescriptor } from 'react-intl';
import en from '../i18n/languages/en';
import es from '../i18n/languages/es';
import pt from '../i18n/languages/pt';
import { mergeMessages } from './utils';

const locale = 'en-US';
const messages: Record<string, any> = {
  'en-US': en,
  'pt-BR': pt,
  'es-419': es,
};

const intlProvider = (locale: string) => {
  const cache = createIntlCache();
  return createIntl(
    {
      locale,
      messages: defineMessages(mergeMessages(messages, locale, locale)),
    },
    cache
  );
};

let provider = intlProvider(locale);

const formatMessage = (message: MessageDescriptor, value?: Record<string, string>): string =>
  provider.formatMessage(message, value);

const updateIntlProvider = (language?: string | null): void => {
  provider = intlProvider(language || locale);
};

const formatDate = (date: number | Date): string => provider.formatDate(date, { timeZone: 'UTC' });

export default { updateIntlProvider, formatMessage, provider, formatDate };
