import { Fade, FormControl, MenuItem, Select } from '@material-ui/core';
import { useUserMetadata } from 'admin-portal-shared-services';
import { LOCAL_STORAGE_COUNTRY } from 'config/constants';
import { useStore } from 'effector-react';
import React from 'react';
import ReactCountryFlag from 'react-country-flag';
import * as userStoreEvents from 'stores/User/UserEvents';
import UserStore from 'stores/User/UserStore';
import { GetUserCountryList } from 'utils/GetUserCountryList/GetUserCountryList';
import useStyles from './SelectCountry.styles';

interface IProps {
  countryCompleteName?: boolean;
  customClass?: string;
}
export interface OptionsItem {
  icon?: string;
  label: string;
  value: string;
}

function SelectCountry({ countryCompleteName, customClass }: IProps): JSX.Element {
  const [countrySelected, setCountrySelected] = React.useState<string>(
    localStorage.getItem(LOCAL_STORAGE_COUNTRY) || ''
  );

  const { selectedCountry } = useStore(UserStore);

  const { formControlClass, iconOption, selectClass } = useStyles();

  const { data } = useUserMetadata();

  const handleSelectedCountry = (country: string) => {
    userStoreEvents.setSelectedCountry(country);
    localStorage.setItem(LOCAL_STORAGE_COUNTRY, country);
  };

  const handleChange = (event: any) => {
    const country = event.target.value;
    handleSelectedCountry(country);
  };

  const options = React.useMemo(() => {
    let options: Array<OptionsItem> = GetUserCountryList(
      data?.supportedCountries,
      countryCompleteName
    );
    return options;
  }, [data]);

  React.useEffect(() => {
    if (selectedCountry) setCountrySelected(selectedCountry);
  }, [selectedCountry]);

  React.useEffect(() => {
    const countrySelectedStorage = localStorage.getItem(LOCAL_STORAGE_COUNTRY);
    if (countrySelectedStorage) {
      userStoreEvents.setSelectedCountry(countrySelectedStorage);
    }
  }, []);

  return (
    <FormControl className={`${formControlClass} ${customClass}`}>
      <Select
        labelId={`selectCountry-label`}
        id={'selectCountry'}
        name={'selectCountry'}
        onChange={handleChange}
        variant="outlined"
        className={selectClass}
        placeholder={'-'}
        value={countrySelected}
        data-testid="select-country"
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
          elevation: 4,
          TransitionComponent: Fade,
        }}
      >
        {options.map((option) => {
          return (
            <MenuItem key={option.value} value={option.value}>
              <div className={iconOption} data-testid="country-options">
                <ReactCountryFlag countryCode={option.value} svg />
                {option.label}
              </div>
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

export default SelectCountry;
