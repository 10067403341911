import MessageMap from '../i18n.d';

const pt: MessageMap = {
  breadCrumbs: {
    home: 'Upload de limites',
    inventory: 'Upload de limites',
    summary: 'Gestão de SKUs limitados',
    clientManagement: 'Gestão de PDVs',
    summaryInventory: 'Resumo de inventario atual',
    criticalInventory: 'Gerenciamento de Estoque Critico',
    rulesManagement: 'Gerenciamento de Regras',
    rulesCreation: 'Criação de Regra',
    rulesEdit: 'Edição da Regra',
    criticalLevel: 'Controle de inventário automatizado',
    atpParameters: 'Gestão de parâmetros do ATP',
    fileMonitoring: 'Monitoramento de arquivos',
    help: 'Ajuda',
  },
  sideMenu: {
    home: 'Upload de limites',
    summary: 'Gestão de SKUs limitados',
    clientManagement: 'Gestão de exceção de PDVs',
    limitPerClient: 'Consulta de limites ativos',
    summaryInventory: 'Resumo de inventário',
    criticalInventory: 'Inventário critico',
    atpParameters: 'Parâmetros do ATP',
    fileMonitoring: 'Monitoramento de arquivos'
  },
  home: {
    title: 'SKUs zerados',
    modelTitle: 'Download modelo',
    modelText: 'Caso ainda não tenha o arquivo padrão, clique no botão abaixo para download.',
    importTitle: 'Upload planilha',
    importText: 'Importe a lista de SKUs que devem ser limitados no BEES',
    modelButtonSKU: 'Arquivo CD',
    modelButtonClient: 'Arquivo PDV',
    DDCOption: 'CD',
    clientOption: 'PDV',
    initialDate: 'Data Inicial',
    endDate: 'Data final',
    dialogTitle: 'Você confirma o envio do arquivo?',
    clientDialogText: 'Tipo de limite: {value}',
    clientDialogDuration: 'Duração: <b>strong period</b>',
    clientDialogPeriod: 'Período: <b>strong period</b>',
    clientTabDateWarning: 'Os limites serão processados fora do horário comercial',
    managementTittle: 'Gerenciamento',
    managementDialog: 'Para visualizar e gerenciar SKUs limitados, clique no botão abaixo.',
    managementButton: 'Gerenciar limites',
  },
  summary: {
    headerTitle: 'Gestão de SKUs limitados',
    title: 'Lista de SKUs por CD',
    titleFilters: 'Filtrar dados',
    downloadTitle: 'Lista de Relatorios Anteriores',
    importLink: 'Upload de arquivo',
    selectAllDDC: 'Selecionar todos os CDs',
    selectAllClients: 'Selecionar todos os CDs',
    selectFieldGeo: 'Selecione a região',
    selectFieldDDC: 'Selecione o CD',
    ddcCheckboxCount: '{count} de {total} CDs selecionados',
    ddcCount: '{value} CDs no total',
    ddcSelectedCount: '{value} CDs selecionados',
    clientCount: '{value} CDs no total',
    clientSelectedCount: '{value} CDs selecionados',
    skuCount: '{value} SKUs no total',
    skuSelectedCount: '{value} SKUs selecionados',
    downloadRequestSuccess:
      'Seu download foi solicitado com sucesso! Consulte a aba de downloads para baixar o arquivo.',
    search: 'Opções de Busca',
    levelRegular: 'Regular',
    levelCritical: 'Crítico',
    filter: {
      placeHolder: 'Busque pelo código ou descrição do SKU',
      optional: '(Opcional)',
    },
  },
  clientManagement: {
    title: 'Gestão de PDVs por exceção ',
    subTitle:
      'Importe a lista de PDVs que possuem alguma exceção no cadastro de CDs. Essa lista irá sobrescrever o CD que é importado pelo BEES.',
    importButton: 'Importar lista de PDVs',
    modelFileButton: 'Arquivo modelo',
    modalTitle:
      'Selecione o arquivo .CSV com a lista de PDVs e CDs que serão tratados como exceção',
    importSuccess: 'CDs exceção importados com sucesso!',
    importError: 'Ocorreu um erro ao importar o arquivo CSV!',
  },
  stockBalance: {
    title: 'Resumo de Inventário Atual',
    subTitle: 'Visão geral',
    filterTitle: 'Relatório personalizado',
    filterSubTitle: 'Crie um relatório com dados de inventário do cliente BEES.',
    skuFilterTooltip: 'Pesquise por SKU digitando ou colando no campo abaixo',
    zeroValueLabel: 'Esconder valores de estoque zero',
    emptyTable: 'O relatório personalizado de inventário aparecerá aqui.',
    distributionCenterTags: 'Centros de distribuição selecionados:',
    bannerAlertText: 'Exportando arquivos. Verifique a página Monitoramento de arquivos para atualizações de status e baixe os arquivos quando estiverem prontos.',
    bannerAlertButton: 'Ir para monitoramento de arquivos',
    table: {
      sku: 'SKU',
      stock: 'Estoque CD',
      reserved: 'Reservado',
      beesStock: 'Estoque BEES',
      outOfStock: 'Esgotado em',
      restocked: 'Reestoque em',
      time: 'Esgotado em (Tempo total)',
      limit: 'Limite CD',
      beesStockRegularStatus: 'Estado regular',
      beesStockCriticalStatus: 'Estado crítico',
      lastUpdated: 'Ultima atualização em',
      pending: 'Pendente',
      consumed: 'Consumido'
    },
    importModal: {
      title: 'Exportar arquivos',
      text: 'Escolha os arquivos que deseja exportar e verifique o andamento na página de monitoramento de arquivos.',
      inventoryTitle: 'Relatório personalizado de inventário',
      inventoryText: 'Exporte região selecionada, centro de distribuição, código SKU e informações de inventário.',
      orderTitle: 'Lista de pedidos reservados',
      orderText: 'Exporte uma lista com todos os pedidos pendentes e confirmados.',
      disableOrderImport: 'Não há nenhuma ordem para ser exportada. Somente são exportadas ordens que tenham reserva pendente.'
    }
  },
  ATPParameters: {
    title: 'Parâmetros do ATP',
    buttons: {
      add: 'Adicionar um novo parâmetro',
    },
    table: {
      ddc: 'DDC',
      skuCount: 'Total SKU',
      type: 'Tipo de parâmetro',
      created: 'Data de criação',
      edited: 'Última alteração',
    },
    modal: {
      addParameter: 'Adicionar Parâmetro',
      editParameter: 'Editar Parâmetro',
      deleteParameter: 'Remover Parâmetro',
      addTitle: 'Adicionar um novo parâmetro',
      editTitle: 'Editar parâmetro',
      allDDC: 'Todos DDCs',
      especificDDC: 'DDCs específicos',
      allSKU: 'Todos SKUs',
      especificSKU: 'SKUs específicos',
      typeAlert:
        'A alteração dos tipos de parâmetro remove quaisquer DDCs e SKUs adicionados dos campos abaixo',
      successCreation: 'Um novo parâmetro foi adicionado com sucesso.',
      successEdition: 'Um novo parâmetro foi editado com sucesso.',
      successDelete: 'Um parâmetro foi removido.',
      labels: {
        paramterType: 'Tipo de Paramêtro',
        ddc: `Selecione DCs`,
        sku: `Selecione SKUs`,
        autocompleteDDC:
          'Use o campo abaixo para digitar o código do DDC e apertar ENTER para adicionar',
        autocompleteSKU:
          'Use o campo abaixo para digitar o código do SKU e apertar ENTER para adicionar',
        placeholderDDC: 'Digite o código do DDC',
        placeholderSKU: 'Digite o código do SKU',
        reservationWindow: 'Janela de Reserva',
        reservationWindowTooltip:
          'Quantos dias antes da data de entrega devemos considerar para reservar os pedidos',
        reservationWindowText: 'Pelo menos uma janela de reserva para o DDC é necessária.',
        statusReservation: 'Status para a reserva',
        statusReservationTooltip:
          'Qual status de pedido do BEES o ATP precisa considerar para parar de reservar cada pedido',
      },
      reservationWindowOptions: {
        single: '1 dia',
        plural: '{value} dias',
        dayLabel: 'Para todo o DDC',
        addNew: 'Adicionar outra janela',
      },
      errors: {
        ddc: 'Precisam ser adicionados os DDC especificos',
        sku: 'Precisam ser adicionados os SKU especificos',
        reservationStatus: 'Pelo menos um status de reserva precisa ser selecionado',
        blockedDDC: 'Ja existe esse DDC cadastrado em outro parâmetro - {value}',
      },
      deleteParameterDialog: {
        title: 'Remover esse parametro?',
        text: 'Essa ação é permanente e não pode ser desfeita.',
      },
    },
    statusConsume: {
      PLACED: 'Order placed',
      CONFIRMED: 'Order confirmed',
      MODIFIED: 'Order modified',
      INVOICED: 'Order invoiced',
      IN_TRANSIT: 'Order in transit',
      DELIVERED: 'Order delivered',
      PARTIAL_DELIVERY: 'Order partial deliveried',
    },
    status: {
      activation: 'Ativação',
      exception: 'Exceção',
    },
    errors: {
      sameSKU: 'Você não pode inserir o mesmo SKU para dias diferentes',
    },
  },
  selector: {
    empty: 'Selecione um CD',
  },
  buttons: {
    import: 'Importar estoque',
    blockSKU: 'Bloquear SKU',
    unblockSKU: 'Desbloquear SKU',
    unblock: 'Desbloquear',
    confirm: 'Confirmar',
    cancel: 'Cancelar',
    importText: 'Clique para buscar em seu computador',
    continue: 'Prosseguir',
    search: 'Buscar',
    download: 'Download',
    apply: 'Aplicar',
    back: 'Voltar',
    review: 'Revisar',
    exit: 'Sair',
    create: 'Criar',
    removeAll: 'Remover todos',
    deactivate: 'Desativar',
    applyChanges: 'Aplicar alterações',
    deleteYes: 'Sim, apagar',
    goBack: 'Volte',
    edit: 'Editar',
    showInventory: 'Mostrar inventário',
    refresh: 'Atualizar',
    gotIt: 'Entendi',
    export: 'Exportar'
  },
  importModal: {
    cancelButton: 'Cancelar',
    submitButton: 'Importar',
    title:
      'Selecione o arquivo .CSV com a listagem de estoque, ao clicar em importar os SKUs do arquivo serão bloqueados para venda no BEES',
    fileInput: 'Clique aqui para selecionar o arquivo',
    formatErrorMessage: 'Tipo de arquivo inválido, selecione um arquivo {value}',
    requestSuccess: 'Limites importados com sucesso!',
    requestFailed: 'Ocorreu um erro ao importar o arquivo CSV!',
    requestFailedEmptyList: 'O arquivo não possui SKUs sem estoque para serem bloqueados',
  },
  SKUUnblockModal: {
    title: 'Você confirma o desbloqueio dos SKUs exibidos abaixo para vendas no BEES?',
    confirm: 'Sim',
    cancel: 'Não',
    success: 'Desbloqueio realizado com sucesso!',
    failure: 'Ocorreu um erro ao desbloquear os SKU!',
  },
  SKUblockSummaryModal: {
    title: 'Você confirma o envio do arquivo?',
    subTitle: 'Veja abaixo os CDs e quantidade de SKUs afetados:',
    type: 'Tipo de limite:',
    cd: 'CD',
    countCd: '{cd} CD',
    countSKU: '{sku} SKUs',
    tableDC: 'Centro de Distribuição',
    tableSKU: 'Quantidade de SKUs',
    failure: 'Ocorreu um erro ao bloquear a lista de SKUs!',
    startDate: 'Início',
    endDate: 'Fim',
    validity: 'Vigência:',
  },
  BoxImportData: {
    title: 'Ultima atualização BEES',
  },
  requestDeliveryCenter: {
    errorList: 'Ocorreu um erro ao listar os CD',
  },
  Pagination: {
    selectedOne: '{value} item selecionado',
    selectedMany: '{value} itens selecionados',
    of: 'de',
  },
  DataGridBlockedSKU: {
    celSku: 'Código e Nome',
    celLimit: 'Limite',
    celAvailable: 'Disponível',
    celDateRange: 'Intervalo de Data',
    celLimitSource: 'Tipo do limite',
    celStartDate: 'Validade Inicial',
    celEndDate: 'Validade Final',
    celAction: 'Ação',
    celPOC: 'PDV',
    celDuration: 'Duração',
    celStatus: 'Status',
    emptyRows: 'Selecione os CDs no campo acima.',
    emptyRowsClients: 'Selecione os PDVs no campo acima.',
    noRows: 'Nenhum SKU encontrado',
    errorRequest: 'Ocorreu um erro ao puxar a listagem de SKU bloqueado',
    errorAccount: 'Conta não encontrada',
    searchField: 'Busque pelo codigo ou a descrição do SKU',
    buttonUnblock: 'Desbloquear',
    noPermission: 'Sem permissão',
    status: {
      pending: 'Pendente',
      success: 'Enviado',
      live: 'Ativo',
    },
  },
  Loading: {
    defaultLoadingBox: 'Os dados estão sendo processados, aguarde',
  },
  datePicker: {
    format: 'dd/MM/yyyy',
    formatTime: 'dd/MM/yyyy HH:mm',
    confirm: 'OK',
    cancel: 'Cancelar',
    incorrectDate: 'Formato de data incorreto',
    minDate: 'Não pode ser menor que a data atual',
    minDateEnd: 'Não pode ser menor que a data inicial',
    defaultDateTimeLabelInitial: 'Data e hora inicial',
    defaultDateTimeLabelEnd: 'Data e hora final',
  },
  importClientLimit: {
    success: 'Limites por PDV importados com sucesso!',
    failure: 'Ocorreu um erro ao importar o arquivo!',
  },
  uploadValidation: {
    fileSize: 'Tamanho limite do arquivo excedido. O tamanho limite de arquivo é {value}MB',
  },
  forms: {
    selectAll: 'Selecione todos',
    unselectAll: 'Desmarcar todos',
    searchField: 'Busque pelo codigo do SKU',
    emptyAtLeastOne: 'Must have at least one item',
    errorChips: 'Remove the following error chips to continue.',
    errorSameChip: 'Você não pode adicionar um item com o mesmo nome',
    searchFieldSKU: 'Código do SKU',
    searchFieldSKUPlaceholder: 'Busque pelo SKU',
  },
  general: {
    for: 'para',
    or: 'ou',
    DDC: 'CD',
    pluralDDC: 'CDs',
    client: 'PDV',
    pluralClient: 'PDVs',
    downloads: 'Relatórios Anteriores',
    filters: 'Filtros',
    region: 'Região',
    undo: 'Desfazer',
    optional: 'Opcional',
    emptySearch: 'Nenhum registro encontrado para sua pesquisa',
    show: 'Mostrar Opções',
    hide: 'Esconder Opções',
    diselectAll: 'Desmarcar todos',
    applied: 'aplicado',
    autocompletecountLabel: '{value} item(s) selecionado(s)',
    clearAll: 'Limpar tudo'
  },
  clientTab: {
    title: 'Lista de SKUs limitados por PDV',
    emptyState: 'Use os campos acima para pesquisar PDVs e SKUs',
  },
  ddc: {
    title: 'Lista de SKUs limitados por CD',
    emptyState: 'Use os campos acima para pesquisar CDS e SKUs',
  },
  uploadList: {
    title: 'Upload',
  },
  uploadHistory: {
    title: 'Histórico de uploads',
  },
  limitPerClient: {
    filter: {
      title: 'Limites ativos por PDV',
      input: 'Busque pelo ID do PDV',
      placeHolder: 'Informe o ID da conta do PDV',
      toolTip:
        'Os dados são obtidos diretamente do BEES para consulta. Atrasos na integração de limites podem resultar em divergências entre as informações exibidas aqui e na tela de gerenciamento de limites.',
    },
    table: {
      empty: 'Informe o CPF ou CNPJ',
      sku: 'SKU',
      limit: 'Limite',
      balance: 'Balanço',
      validStart: 'Validade Inicial',
      validEnd: 'Validade Final',
      loading: 'Buscando itens',
      pageSizeOptions: 'Elementos por página: {value}',
      pageNumberIndicator: 'de',
      searchPlaceHolder: 'Busque por SKU',
      status: 'Status',
      limitType: 'Tipo'
    },
    notfound: 'Nenhum resultado foi encontrado!',
    error: {
      size: 'Tamanho invalido',
      cpforcpnj: 'CPF/CNPJ inválido',
      length: 'O campo deve ter mais de 1 dígito',
    },
    imageText: ' Veja os limites de SKU ativos por PDV',
  },
  exportSKUList: {
    table: {
      requestDate: 'Data',
      limitType: 'Tipo de limite',
      User: 'Usuário',
      Status: 'Status',
      Actions: 'Ação',
      Info: 'Informação',
    },
    filterTag: {
      limitType: '[Tipo] {value}',
      user: '[Usuário] {value}',
      status: '[Status] {value}',
      dc: '[CD] {value}',
      name: '[Nome] {value}',
      SKU: '[SKU] {value}',
    },
    status: {
      finished: 'Pronto para baixar',
      inProgress: 'Em andamento',
      failed: 'Falhou',
    },
  },
  customRequestErrors: {
    importSKUFile: 'Você não possui permissão para definir limites para os CDs {value}',
    defaultRequestErrors: 'Um erro inesperado aconteceu',
    defaultRequestErrorsATP: 'Ops, algo deu errado, por favor tente novamente mais tarde',
  },
  localErrors: {
    timezoneImport:
      'Erro ao importar o arquivo. É necessário selecionar um fuso horário no seu perfil de usuário.',
    timezoneDefault: 'É necessário selecionar um fuso horário no seu perfil de usuário.',
    emptyCountryList:
      'Você não possui nenhum vendor associado ao seu usuário, por favor contate o time de suporte',
  },
  ModalSelectCountry: {
    title: 'Bem-vindo!',
    text: 'Antes de usar o LOAT, selecione o país que irá visualizar.',
    label: 'País:',
    emptyTitle: 'Cadastro incompleto',
    emptyText:
      'Parece que seu cadastro de usuário não está completo. Entre em contato com o responsável pelo seu usuário e tente novamente mais tarde.',
  },
  Countries: {
    AR: 'Argentina',
    AQ: 'Antártica',
    BE: 'Bélgica',
    BR: 'Brasil',
    BO: 'Bolivia',
    CL: 'Chile',
    CO: 'Colombia',
    DE: 'Alemanha',
    DO: 'Dominican Republic',
    EC: 'Ecuador',
    ES: 'Espanha',
    GB: 'Reino Unido',
    HN: 'Honduras',
    ID: 'Indonesia',
    MX: 'Mexico',
    NL: 'Holanda',
    PA: 'Panama',
    PE: 'Peru',
    PH: 'Filipinas',
    PY: 'Paraguay',
    SV: 'El Salvador',
    UY: 'Uruguay',
    ZA: 'South Africa',
  },
  ToolTipForTimeZone: {
    title: 'Nenhum fuso selecionado',
  },
  UploadStatus: {
    inProgress: 'Em progresso',
    uploadSuccessfully: 'Enviado',
    error: 'Falha',
    uploadWithErrors: 'Enviado com erros',
  },
  ImportFileDataTable: {
    fileName: 'Nome do Arquivo',
    uploadDate: 'Data de upload',
    uploadBy: 'Enviado por',
    uploadStatus: 'Status do upload',
    limitType: 'Tipo de limite',
    lines: 'Linhas',
    successes: 'Sucesso',
    fails: 'Falha',
    toolTip: {
      titleWithErrors: 'Clique para visualizar os erros',
      titleWithoutErrors: 'Não há erros para download',
    },
  },
  FiltersTitle: {
    region: 'Região e CD',
    limitType: 'Tipo de Limite',
    user: 'Usuário',
    status: 'Status',
    SKU: 'SKU',
    RuleName: 'Regra de limite',
  },
  Placeholders: {
    user: 'Digite um e-mail',
    limitType: 'Selecione um tipo de limite',
    status: 'Selecione um status',
    SKU: 'SKU',
    RuleName: 'Regra de limite',
    searchSKU: 'Buscar por SKU ou nome',
  },
  ExportLimitTypes: {
    finished: 'Pronto para baixar',
    in_progress: 'Em andamento',
    failed: 'Falhou',
  },
  DownloadErrorFiles: {
    title: 'Baixar como arquivo CSV',
  },
  DialogWithTableError: {
    title: 'Lista de erros',
    noRows: 'Nenhum arquivo foi encontrado',
    overRows: 'Baixe o arquivo para visualizar todos os erros',
    loading: 'Aguarde enquanto os dados são carregados',
    column: {
      line: 'Linha',
      message: 'Descrição do erro',
    },
  },
  criticalInventory: {
    title: 'Gerenciamento de estoque crítico',
    subTitle: 'Estabeleça um nível crítico de estoque e configure regras de limites',
    text: 'Siga os passos abaixo:',
    stepOneTitle: 'PASSO 1. Nível de estoque crítico',
    stepOneText: 'Estabeleça os valores para o nível crítico de estoque por CDD e SKU',
    stepTwoTitle: 'PASSO 2. Regras de limite',
    stepTwoText: 'Crie e gerencie regras de limite por CDD e PDV ',
    form: {
      stepOneText: 'Criar',
      stepOneEditText: 'Editar',
      stepTwoText: 'Revisar',
      dialogBackTitle: 'Você deseja sair?',
      dialogBackText:
        'O conteúdo da sua regra não será salvo, você terá que recomeçar se quiser criar a regra.',
      successCreation: 'Sua regra foi criada! ',
      successEdition: 'Sua regra foi editada! ',
      pocRequestError: 'Todas as POCs inseridas são inválidas. Informe POCs existentes',
      StepOne: {
        title: 'Nomeia sua regra e defina o tipo de regra:',
        RuleNameLabel: 'Nome da Regra',
        RuleNamePlaceholder: 'O nome deve ter no máximo 50 caracteres',
        RuleTypeLabel: 'Tipo de regra',
        RuleDescription: 'Do que se trata essa regra?',
        RuleDescriptionDDC:
          'Este tipo de regra ativará ou desativará automaticamente um SKU para todos os PDVs no DC.',
        RuleDescriptionPOC:
          'Esse tipo de regra limitará os PDVs que você escolher para ter uma quantidade específica de um SKU disponível para compra.',
        turnOn: 'Ativar Regra',
        turnOff: 'Desativar Regra',
        dialogStatusTitle: 'Você quer desativar essa regra?',
        dialogStatusText:
          'Esta ação será imediata e a regra permanecerá inativa até que seja ativada novamente.',
      },
      StepTwo: {
        title: 'Defina um CD para colocar sua regra:',
        RegionLabel: 'Região',
        RegionPlaceholder: 'Selecione uma Região',
        DDCLabel: 'Centros de distribuição',
        DDCPlaceholder: 'Selecione um CD',
      },
      StepThree: {
        title: 'Escolha o produto que sera limitado',
        SKULabel: 'SKU',
        SKUPlaceholder: 'Lista de SKUs',
        Qty: 'Quantidade de limite',
        actions: 'Ações',
        skuHaveRuleError: 'O sku {value} já tem uma regra criada.',
      },
      StepFour: {
        title: 'Escolha os PDVs',
        POCLabel: 'PDVs',
        POCPlaceholder: 'Lista de PDV',
        maxPOCWarningTitle: 'Você enviou mais PDVs do que o permitido!',
        maxPOCWarningText:
          'A criação de uma regra de PDV permite apenas o upload de até 400 PDVs, você pode optar por excluir o último número excedente de PDVS ou voltar e copiar e colar o valor exato.',
        maxPOCWarningAccept: 'Excluir os PDV excedentes',
        maxPOCToastError: `Você nao pode adicionar novos PDVs, o limite de {value} foi alcançado`,
        informationTextChip: 'Você também pode copiar e colar 400 PDV do seu CSV',
      },
      Review: {
        title: 'Revise a sua informação',
      },
    },
    Filter: {
      clearStates: 'Limpar filtro',
    },
  },
  rulesManagementList: {
    title: 'Gerenciamento de Regra',
    createRule: 'Criar regra',
    table: {
      emptyTitle: 'Criar regra',
      emptyText: 'Não existem regras criadas, adicione uma e visualize as informações aqui!',
      name: 'Nome da Regra',
      type: 'Tipo de Regra',
      ddc: 'DC',
      sku: 'SKU',
      qty: 'Quantidade de limite',
      PDV: `PDVs`,
      actions: 'Ações',
      lastEvent: 'Último evento',
      status: 'Status',
    },
  },
  criticalLevel: {
    title: 'Controle de inventário automatizado',
    searchPlaceholder: 'Buscar por DDC ou SKU',
    createCriticalLevel: 'Adicionar novo nível crítico',
    table: {
      emptyTitle: 'Criar uma regra de estoque crítico',
      emptyText: 'Não existem regras criadas, adicionar uma para visualizar as informações aqui!',
      ddc: 'DDC',
      sku: 'SKU id',
      criticalLevel: 'Estoque crítico',
      created: 'Data criada',
      updated: 'Última atualização',
    },
    modal: {
      title: 'Adicionar novo nível crítico',
      titleEdit: 'Editar nível crítico',
      regionPlaceholder: 'Selecionar a região',
      skuPlaceholder: 'Buscar SKU',
      skuText: 'Busque um SKU digitando ou colando no campo abaixo',
      criticalLevelInput: 'Adicionar valor de nível crítico',
      criticalLevelPlaceholder: 'Inserir npumero',
      successInsert: 'Um novo nível crítico foi adicionado com sucesso.',
      successUpdate: 'Mudanças aplicadas ao nível crítico.',
      successDelete: 'Nível crítico removido.',
      deleteDialogTitle: 'Remover nível crítico',
      deleteDialogText: 'Essa ação é permanente e não pode ser desfeita.',
    },
  },
  fileMonitoring: {
    ATPExport: {
      table: {
        type: 'Tipo',
        fileName: 'Nome do arquivo',
        exportedOn: 'Exportado em',
        exportedBy: 'Exportado por',
        status: 'Status',
        action: 'Ação'
      },
      typeText: {
        inventory: 'Relatório personalizado de inventário',
        order: 'Lista de pedidos reservados ATP'
      },
      dialogError: {
        title: 'Não foi possível processar o arquivo',
        text: 'Estamos enfrentando problemas para processar o arquivo. Tente importar novamente'
      }
    }
  },
  notFoundPage: {
    titleAccessDenied: 'Hmm... Acesso negado!',
    textAccessDenied: `Parece que você não tem permissão para ver está página.`
  },
  defaultStatusTypes: {
    active: 'Ativo',
    inactive: 'Inativo',
    applied: 'Aplicado',
    processing: 'Processando',
    error: 'Erro',
    readyDownload: 'Pronto para download'
  },
};

export default pt;
